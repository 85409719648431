<template>
  <b-container class="container-content" id="faq">
    <div class="page-header">
      <h4 class="page-header-text">{{ $t('nav.faq') }}</h4>
    </div>
    <b-form class="mb-4">
      <b-form-group>
        <b-form-input
          :placeholder="$t('form_label.ask_a_question')"
          class="input-search"
          v-model="queryString"
        ></b-form-input>
      </b-form-group>
    </b-form>
    <div class="collapse-question primary">
      <div v-for="(key, index) in groupKeys" :key="index">
        <b-link class="collapse-header" v-b-toggle="faqFilterResult[key].path">{{
          faqFilterResult[key].label
        }}</b-link>
        <b-collapse :id="faqFilterResult[key].path" visible>
          <div v-for="(q, index) in faqFilterResult[key].questions" :key="index">
            <b-link class="collapse-header ml-4" v-b-toggle="q.path">
              {{ q.question }}
            </b-link>
            <b-collapse :id="q.path" class="ml-4" :visible="isVisible">
              <p v-for="(ans, index) in q.answer" :key="index" class="ml-4">
                <span v-if="ans.label">{{ ans.label }}<br /></span>
                {{ ans.text }}
              </p>
            </b-collapse>
          </div>
        </b-collapse>
      </div>
    </div>
  </b-container>
</template>

<script>
import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import ContentEN from '../../locales/en.json'
import ContentTH from '../../locales/th.json'

// import { createMeta } from '../../config/seo'

@Component({
  // metaInfo() {
  //   return createMeta({
  //     title: this.$i18n.locale === 'th' ? 'คำถามที่พบบ่อย' : 'FAQ',
  //     lang: this.$i18n.locale
  //   })
  // }
})
export default class Faq extends Vue {
  groupKeys = Object.keys(ContentEN.pages.faq)
  queryString = ''

  faqFilterResult = {}
  timeOut = undefined

  isVisible = ( window.__prerender && window.__prerender.prerendering ) || false

  get faq() {
    return this.$i18n.locale === 'th' ? ContentTH.pages.faq : ContentEN.pages.faq
  }

  created() {
    this.faqFilterResult = this.faq
  }

  @Watch('queryString')
  onQueryStringChanged() {
    clearTimeout(this.timeOut)
    this.timeOut = setTimeout(() => {
      if (this.queryString) {
        const faqResult = {}
        for (let key in this.faq) {
          faqResult[key] = {
            ...this.faq[key],
            questions: this.faq[key].questions.filter(questionObj => {
              const lowerQuestion = questionObj.question.toLowerCase()
              const isFoundInAns = questionObj.answer.some(({ text, label }) => {
                const includeInAns = text.toLowerCase().includes(this.queryString)
                const includesInLabel = label
                  ? label.toLowerCase().includes(this.queryString)
                  : false
                return includeInAns || includesInLabel
              })
              return lowerQuestion.includes(this.queryString) || isFoundInAns
            })
          }
        }
        this.faqFilterResult = { ...faqResult }
      } else {
        this.faqFilterResult = this.faq
      }
    }, 500)
  }
}
</script>

<style lang="scss" scoped>
.collapse-question {
  a,
  p {
    font-weight: 400;
  }
}
</style>
